<template>
	<main>
		<div class="container">
			<div class="list-group">
				<router-link to="/cl2/sales" class="list-group-item list-group-item-action">CL2 - Sales</router-link>
				<!-- <router-link to="/cl2/stock" class="list-group-item list-group-item-action">CL2 - Stock</router-link> -->
				<router-link to="/cl9/sales" class="list-group-item list-group-item-action">CL9 - Sales</router-link>
				<!-- <router-link to="/cl9/stock" class="list-group-item list-group-item-action">CL9 - Stock</router-link> -->
			</div>
		</div>
	</main>
</template>

<script>

export default {
	name: 'Home',
}
</script>
