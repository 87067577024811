import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Cl2Sales from '../views/cl2/sales.vue'
import Cl2SalesRegister from '../views/cl2/sales_register.vue'

import Cl2Stock from '../views/cl2/stock.vue'
import Cl2StockRegister from '../views/cl2/stock_register.vue'

import About from '../views/About.vue'

import Cl9Sales from '../views/cl9/sales.vue'

Vue.use(VueRouter)

const routes = [
	{path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/about',
		name: 'About',
		component: About
	},
	{
		path: '/cl2/sales',
		name: 'CL2-Sales',
		component: Cl2Sales
	},
	{
		path: '/cl2/sales/register',
		name: 'CL2-Sales-Register',
		component: Cl2SalesRegister
	},
	{
		path: '/cl2/stock',
		name: 'CL2-stock',
		component: Cl2Stock
	},
	{
		path: '/cl2/stock/register',
		name: 'CL2-Stock-Register',
		component: Cl2StockRegister
	},
	{
		path: '/cl9/sales',
		name: 'CL9-Sales',
		component: Cl9Sales
	},
]

const router = new VueRouter({
	routes
})

export default router
