<template>
	<main>
		<div v-show="list==0">
		<div class="container">
			<div class="sales-item">
				<div class="row">
					<div class="col-sm-6">
						<img class="icon" width="250px" src="../../assets/sales@icon.png">
					</div>
					<div class="col-sm-6 text-right">
						<span class="date">{{ date | moment("D MMMM YYYY, dddd") }}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-2 offset-sm-9">
						<span class="item_no">{{ key+1 }}</span> / 
						<span class="total_item">{{ items.length }}</span>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-sm-2">
						<button class="btn btn-lg btn-action" @click="checkQuantity('Back')" :disabled="key==0">
							<i class="fas fa-chevron-left"></i>
						</button>
					</div>
					<div class="col-sm-8">
						<div class="card shadow-sm">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-sm-5">
										<img height="200px" :src="getImage()">
									</div>
									<div class="col-sm-7">
										<span class="title">KSBCL SKU CODE</span>
										<p class="sku">{{ items[key].sku }}</p>

										<span class="title">ALIAS</span>
										<input :readonly="status==0" class="alias" type="text" v-model="items[key].alias">
										<button class="btn" v-if="status==0" @click="editAlias">
											<i class="fas fa-pencil-alt"></i>
										</button>
										<button class="btn btn-edit" v-if="status==1" @click="updateAlias">
											Save
											<i class="fas fa-chevron-right"></i>
										</button>
										<button class="btn btn-sm btn-unit">
											{{ items[key].size }}{{ items[key].unit }}
										</button>

										<p class="quantity">SALES (QUANTITY)</p>
										<input class="form-control" type="text" ref="quantity" v-model="items[key].quantity" @keyup.enter="checkQuantity('Next')">
										<p class="stock">STOCK ON HAND: {{ +items[key].os + +items[key].ps }} QTY</p>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-sm-2" style="text-align:right;">
						<button class="btn btn-lg btn-action" @click="checkQuantity('Next')" :disabled="key==(items.length-1)">
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 text-center">
						<p class="enter">Entered {{ getCount() }} | To be Entered: {{ getTotalCount() }}</p>
						<button class="btn btn-submit" @click="checkQuantity('submit')">
							VIEW AND SUBMIT 
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</div>

		<b-modal size="lg" id="confirm" centered :hide-header="true" :hide-footer="true">
			<div class="custom-modal">
				<div class="row">
					<div class="col-sm-12">
						<h4 class="heading">Important</h4>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" checked id="check">
							<label class="form-check-label" for="check">
								Closing Stock has been calculated by the system, please make sure you do the physical count before submitting
							</label>
						</div>
					</div>
				</div>
				<div class="row btn-action justify-content-center">
					<div class="col-sm-5">
						<button class="btn btn-back" @click="closeModal">
							<i class="fas fa-chevron-left"></i>
							BACK
						</button>
					</div>
					<div class="col-sm-5">
						<button class="btn btn-proceed" @click="confirmModal">
							AGREE TO PROCEED
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>
		</div>
		<div v-show="list==1">
			<div class="container">
			<div class="excise-register">
				<div class="main row align-items-center">
					<div class="col-sm-6">
						<span class="register">Excise Register</span>
					</div>
					<div class="col-sm-6 text-right">
						<span class="date">{{ date | moment("D MMMM YYYY, dddd") }}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered excicetable">
							<thead>
								<tr class="main-heading table_headMain redtblbdrright">
									<th>KSBCL SKU CODE</th>
									<th>OPENING STOCK</th>
									<th>PURCHASE</th>
									<th>SALES</th>
									<th>CLOSING STOCK</th>
									<th>ACTION</th>
								</tr>
								<tr class="heading table_l">
									<th></th>
									<th>Quantity</th>
									<th>Quantity</th>
									<th>Quantity</th>
									<th>Quantity</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,key) in items" :key="key" class="table_l" :style="getColor(item)">
									<td>{{ item.sku }}</td>
									<td>{{ item.os }}</td>
									<td>{{ item.ps }}</td>
									<td v-if="item.quantity">{{ item.quantity }}</td>
									<td v-else>0</td>
									<td v-if="item.quantity">{{ (parseInt(item.os) + parseInt(item.ps)) - parseInt(item.quantity) }}</td>
									<td v-else>{{ (parseInt(item.os) + parseInt(item.ps)) }}</td>
									
									<td class="text-center"><a @click="editsales(key)"><i class="nxtarw fas fa-pencil-alt"></i></a></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 text-right">
						<button class="btn btn-back">
							<i class="fas fa-chevron-left"></i>
							BACK
						</button>
					</div>
					<div class="col-sm-6">
						<button class="btn btn-submit" @click="openModal">
							SUBMIT
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</div>

		<b-modal size="lg" id="confirm" centered :hide-header="true" :hide-footer="true">
			<div class="custom-modal">
				<div class="row">
					<div class="col-sm-12">
						<h4 class="heading">Important</h4>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" checked id="check">
							<label class="form-check-label" for="check">
								Please make sure you have done physical count to match closing balance
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" checked id="check2">
							<label class="form-check-label" for="check2">
								Once confirmed, data cannot be modified later
							</label>
						</div>
					</div>
				</div>
				<div class="row btn-action justify-content-center">
					<div class="col-sm-5">
						<button class="btn btn-back" @click="closeModal">
							<i class="fas fa-chevron-left"></i>
							BACK
						</button>
					</div>
					<div class="col-sm-5">
						<button class="btn btn-proceed" @click="confirmModal">
							SUBMIT
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal id="success" centered :hide-header="true" :hide-footer="true">
			<div class="custom-modal">
				<div class="row">
					<div class="col-sm-12 text-center">
						<img class="icon" src="../../assets/success.png">
						<p>Excise Register Successfully Submitted as on</p> 
						<p>{{ date | moment("D MMMM YYYY, dddd") }}</p>
					</div>
				</div>
				<div class="row btn-action justify-content-center">
					<div class="col-sm-5">
						<button class="btn btn-proceed" @click="successModal">
							OK
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>
		</div>
	</main>
</template>

<script>

export default {
	name: 'CL2-Sales',
	data(){
		return{
			list:0,
			items:[
				{
					"sku": "MCDOWELLS NO. 1 WHISKEY 60",
					"alias": "MCDOWELLS NO. 1 WHISKEY 60",
					"size": "60",
					"unit": "ML",
					"os": "150",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "1.png"
				},
				{
					"sku": "Amora RED Wine 750ML(0512)",
					"alias": "Amora RED Wine 750ML(0512)",
					"size": "750",
					"unit": "ML",
					"os": "260",
					"ps": "18",
					"cs": "0",
					"quantity":"",
					"image": "2.png"
				},
				{
					"sku": "Chachi RED Wine(Fruit Wine)-PET-750MLx12 P.Btls.(0099)",
					"alias": "Chachi RED Wine(Fruit Wine)-PET-750MLx12 P.Btls.(0099)",
					"size": "750",
					"unit": "ML",
					"os": "280",
					"ps": "16",
					"cs": "0",
					"quantity":"",
					"image": "3.png"
				},
				{
					"sku": "Dindori Reserve Shiraz-750MLx12Btls.(0523)",
					"alias": "Dindori Reserve Shiraz-750MLx12Btls.(0523)",
					"size": "750",
					"unit": "ML",
					"os": "300",
					"ps": "18",
					"cs": "0",
					"quantity":"",
					"image": "4.png"
				},
				{
					"sku": "Fratelli Cabernet Sauvignon 750ML(0518)",
					"alias": "Fratelli Cabernet Sauvignon 750ML(0518)",
					"size": "750",
					"unit": "ML",
					"os": "350",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "5.png"
				},
				{
					"sku": "Fratelli Chardonnay 750ML(0518)",
					"alias": "Fratelli Chardonnay 750ML(0518)",
					"size": "750",
					"unit": "ML",
					"os": "400",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "6.png"
				},
				{
					"sku": "Fratelli Chenin Blanc 750ML(0518)",
					"alias": "Fratelli Chenin Blanc 750ML(0518)",
					"size": "750",
					"unit": "ML",
					"os": "444",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "7.png"
				},
				{
					"sku": "Golden Sparrow Sangiovese Rubicone 750ML(0903)",
					"alias": "Golden Sparrow Sangiovese Rubicone 750ML(0903)",
					"size": "750",
					"unit": "ML",
					"os": "488",
					"ps": "11",
					"cs": "0",
					"quantity":"",
					"image": "8.png"
				},
				{
					"sku": "Grover Art Collection Merlot Red Wine 750ML(0512)",
					"alias": "Grover Art Collection Merlot Red Wine 750ML(0512)",
					"size": "750",
					"unit": "ML",
					"os": "532",
					"ps": "16",
					"cs": "0",
					"quantity":"",
					"image": "9.png"
				},
				{
					"sku": "Hardys Stamp Cabernet Merlot 750ML(0879)",
					"alias": "Hardys Stamp Cabernet Merlot 750ML(0879)",
					"size": "750",
					"unit": "ML",
					"os": "576",
					"ps": "18",
					"cs": "0",
					"quantity":"",
					"image": "10.png"
				},
				{
					"sku": "Kadu Sauvignon Blanc White Wine 750ML(0523)",
					"alias": "Kadu Sauvignon Blanc White Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "620",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "11.png"
				},
				{
					"sku": "Karnataka Port Gold Sweet Red Wine 750ML(0511)",
					"alias": "Karnataka Port Gold Sweet Red Wine 750ML(0511)",
					"size": "750",
					"unit": "ML",
					"os": "664",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "12.png"
				},
				{
					"sku": "Limited Shiraz Red wine Spring 2016 750MLx12Btls(0520)",
					"alias": "Limited Shiraz Red wine Spring 2016 750MLx12Btls(0520)",
					"size": "750",
					"unit": "ML",
					"os": "708",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "13.png"
				},
				{
					"sku": "Madera RED Wine 750ML(0523)",
					"alias": "Madera RED Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "752",
					"ps": "14",
					"cs": "0",
					"quantity":"",
					"image": "14.png"
				},
				{
					"sku": "Madera WHITE Wine 750ML(0523)",
					"alias": "Madera WHITE Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "796",
					"ps": "13",
					"cs": "0",
					"quantity":"",
					"image": "15.png"
				},
				{
					"sku": "Port Wine 1000 750ML(0523)",
					"alias": "Port Wine 1000 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "840",
					"ps": "17",
					"cs": "0",
					"quantity":"",
					"image": "16.png"
				},
				{
					"sku": "Port Wine 1000 Gold 750ML(0523)",
					"alias": "Port Wine 1000 Gold 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "884",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "17.png"
				},
				{
					"sku": "Raya Red Wine 750ML(0512)",
					"alias": "Raya Red Wine 750ML(0512)",
					"size": "750",
					"unit": "ML",
					"os": "928",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "18.png"
				},
				{
					"sku": "Samara RED Wine 750ML(0523)",
					"alias": "Samara RED Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "972",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "19.png"
				},
				{
					"sku": "Samara WHITE Wine 750ML(0523)",
					"alias": "Samara WHITE Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "1016",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "20.png"
				},
				{
					"sku": "Sula Dindori Reserva Viognier White Wine 750ML(0196)",
					"alias": "Sula Dindori Reserva Viognier White Wine 750ML(0196)",
					"size": "750",
					"unit": "ML",
					"os": "1060",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "21.png"
				},
				{
					"sku": "Sula Shiraz Cabernet Red Wine 750ML(0523)",
					"alias": "Sula Shiraz Cabernet Red Wine 750ML(0523)",
					"size": "750",
					"unit": "ML",
					"os": "260",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "22.png"
				},
				{
					"sku": "Two Oceans Chardonnay 750ML(0903)",
					"alias": "Two Oceans Chardonnay 750ML(0903)",
					"size": "750",
					"unit": "ML",
					"os": "280",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "23.png"
				},
				{
					"sku": "Martini Extra Dry 1000ML X 12Btls(0432)",
					"alias": "Martini Extra Dry 1000ML X 12Btls(0432)",
					"size": "1000",
					"unit": "ML",
					"os": "300",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "24.png"
				},
				{
					"sku": "Bacardi Super Deluxe Plus Cranberry-275MLx24Btls.(0057)",
					"alias": "Bacardi Super Deluxe Plus Cranberry-275MLx24Btls.(0057)",
					"size": "275",
					"unit": "ML",
					"os": "350",
					"ps": "14",
					"cs": "0",
					"quantity":"",
					"image": "25.png"
				},
				{
					"sku": "Breezer Super Deluxe Blackberry Crush-275MLx24Btls.(0057)",
					"alias": "Breezer Super Deluxe Blackberry Crush-275MLx24Btls.(0057)",
					"size": "275",
					"unit": "ML",
					"os": "400",
					"ps": "13",
					"cs": "0",
					"quantity":"",
					"image": "26.png"
				},
				{
					"sku": "8PM Rare Blend of Indian Whisky & Malts-PET 750ML(0521)",
					"alias": "8PM Rare Blend of Indian Whisky & Malts-PET 750ML(0521)",
					"size": "750",
					"unit": "ML",
					"os": "444",
					"ps": "17",
					"cs": "0",
					"quantity":"",
					"image": "27.png"
				},
				{
					"sku": "Amrut Amalgam Malt Whisky 750ML(0009)",
					"alias": "Amrut Amalgam Malt Whisky 750ML(0009)",
					"size": "750",
					"unit": "ML",
					"os": "488",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "28.png"
				},
				{
					"sku": "Amrut Fusion Single Malt Whisky (42.8%) 750ML(0009)",
					"alias": "Amrut Fusion Single Malt Whisky (42.8%) 750ML(0009)",
					"size": "750",
					"unit": "ML",
					"os": "532",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "29.png"
				},
				{
					"sku": "Bagpiper Delux Whisky-TETRA PACK 180ML T.PACK(0006)",
					"alias": "Bagpiper Delux Whisky-TETRA PACK 180ML T.PACK(0006)",
					"size": "750",
					"unit": "ML",
					"os": "576",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "30.png"
				},
				{
					"sku": "DSP Black Deluxe Whisky 750ML(0514)",
					"alias": "DSP Black Deluxe Whisky 750ML(0514)",
					"size": "750",
					"unit": "ML",
					"os": "620",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "31.png"
				},
				{
					"sku": "Dewars White Label Blended Scotch Whisky Super Deluxe-750MLX12Btls.(0057)",
					"alias": "Dewars White Label Blended Scotch Whisky Super Deluxe-750MLX12Btls.(0057)",
					"size": "750",
					"unit": "ML",
					"os": "664",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "32.png"
				},
				{
					"sku": "Directors Special Whisky-ASEPTIC BRIK PACK 180ML(0033)",
					"alias": "Directors Special Whisky-ASEPTIC BRIK PACK 180ML(0033)",
					"size": "180",
					"unit": "ML",
					"os": "708",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "33.png"
				},
				{
					"sku": "Directors Special Whisky-ASEPTIC TETRA PACK 90MLX96 A.T.Pack(0038)",
					"alias": "Directors Special Whisky-ASEPTIC TETRA PACK 90MLX96 A.T.Pack(0038)",
					"size": "90",
					"unit": "ML",
					"os": "752",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "34.png"
				},
				{
					"sku": "Directors Special Whisky-PET 1000ML(0020)",
					"alias": "Directors Special Whisky-PET 1000ML(0020)",
					"size": "1000",
					"unit": "ML",
					"os": "796",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "35.png"
				},
				{
					"sku": "Glenfiddich 15 Y O Single Malt Scotch Whisky 700MLx12Btls(0380)",
					"alias": "Glenfiddich 15 Y O Single Malt Scotch Whisky 700MLx12Btls(0380)",
					"size": "750",
					"unit": "ML",
					"os": "840",
					"ps": "14",
					"cs": "0",
					"quantity":"",
					"image": "36.png"
				},
				{
					"sku": "Glenkinchie Single Malt Scotch Whisky 12 Years Old 750ML(0866)",
					"alias": "Glenkinchie Single Malt Scotch Whisky 12 Years Old 750ML(0866)",
					"size": "750",
					"unit": "ML",
					"os": "260",
					"ps": "13",
					"cs": "0",
					"quantity":"",
					"image": "37.png"
				},
				{
					"sku": "Johnnie Walker Double Black Blended Scotch Whisky 750ML(0866)",
					"alias": "Johnnie Walker Double Black Blended Scotch Whisky 750ML(0866)",
					"size": "750",
					"unit": "ML",
					"os": "280",
					"ps": "17",
					"cs": "0",
					"quantity":"",
					"image": "38.png"
				},
				{
					"sku": "Johnnie Walker Red Rye Finish Blended Scotch Whisky 700MLx12Btls(0866)",
					"alias": "Johnnie Walker Red Rye Finish Blended Scotch Whisky 700MLx12Btls(0866)",
					"size": "700",
					"unit": "ML",
					"os": "300",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "39.png"
				},
				{
					"sku": "Legion Premium Blended Whisky 750MLx12Btls(0661)",
					"alias": "Legion Premium Blended Whisky 750MLx12Btls(0661)",
					"size": "750",
					"unit": "ML",
					"os": "350",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "40.png"
				},
				{
					"sku": "McDowells No.1 Reserve Whisky 1000ML(0033)",
					"alias": "McDowells No.1 Reserve Whisky 1000ML(0033)",
					"size": "1000",
					"unit": "ML",
					"os": "400",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "41.png"
				},
				{
					"sku": "McDowells No.1 Reserve Whisky 180ML(0033)",
					"alias": "McDowells No.1 Reserve Whisky 180ML(0033)",
					"size": "180",
					"unit": "ML",
					"os": "444",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "42.png"
				},
				{
					"sku": "McDowells No.1 Reserve Whisky 750ML(0033)",
					"alias": "McDowells No.1 Reserve Whisky 750ML(0033)",
					"size": "750",
					"unit": "ML",
					"os": "488",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "43.png"
				},
				{
					"sku": "Mcdowells No.1 Luxury Select Whisky-Aseptic Brick Pack 90MLx96ABP(0033)",
					"alias": "Mcdowells No.1 Luxury Select Whisky-Aseptic Brick Pack 90MLx96ABP(0033)",
					"size": "90",
					"unit": "ML",
					"os": "532",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "44.png"
				},
				{
					"sku": "Mcdowells No.1 Premier Whisky-Aseptic Brick Pack 90MLx96ABP(0033)",
					"alias": "Mcdowells No.1 Premier Whisky-Aseptic Brick Pack 90MLx96ABP(0033)",
					"size": "90",
					"unit": "ML",
					"os": "576",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "45.png"
				},
				{
					"sku": "Seagram's Blenders Pride Rare Premium Whisky 180ML(0091)",
					"alias": "Seagram's Blenders Pride Rare Premium Whisky 180ML(0091)",
					"size": "180",
					"unit": "ML",
					"os": "620",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "46.png"
				},
				{
					"sku": "Seagram's Blenders Pride Rare Premium Whisky 375ML(0091)",
					"alias": "Seagram's Blenders Pride Rare Premium Whisky 375ML(0091)",
					"size": "375",
					"unit": "ML",
					"os": "664",
					"ps": "14",
					"cs": "0",
					"quantity":"",
					"image": "47.png"
				},
				{
					"sku": "Seagram's Blenders Pride Rare Premium Whisky 750ML(0091)",
					"alias": "Seagram's Blenders Pride Rare Premium Whisky 750ML(0091)",
					"size": "750",
					"unit": "ML",
					"os": "708",
					"ps": "13",
					"cs": "0",
					"quantity":"",
					"image": "48.png"
				},
				{
					"sku": "Seagram's Imperial Blue Superior Grain Whisky 180ML(0091)",
					"alias": "Seagram's Imperial Blue Superior Grain Whisky 180ML(0091)",
					"size": "180",
					"unit": "ML",
					"os": "752",
					"ps": "17",
					"cs": "0",
					"quantity":"",
					"image": "49.png"
				},
				{
					"sku": "Seagram's Imperial Blue Superior Grain Whisky 375ML(0091)",
					"alias": "Seagram's Imperial Blue Superior Grain Whisky 375ML(0091)",
					"size": "375",
					"unit": "ML",
					"os": "796",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "50.png"
				},
				{
					"sku": "Seagram's Imperial Blue Superior Grain Whisky 750ML(0091)",
					"alias": "Seagram's Imperial Blue Superior Grain Whisky 750ML(0091)",
					"size": "750",
					"unit": "ML",
					"os": "840",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "51.png"
				},
				{
					"sku": "Seagram's Royal Stag Deluxe Whisky 180ML(0091)",
					"alias": "Seagram's Royal Stag Deluxe Whisky 180ML(0091)",
					"size": "180",
					"unit": "ML",
					"os": "884",
					"ps": "12",
					"cs": "0",
					"quantity":"",
					"image": "52.png"
				},
				{
					"sku": "Seagram's Royal Stag Deluxe Whisky 375ML(0091)",
					"alias": "Seagram's Royal Stag Deluxe Whisky 375ML(0091)",
					"size": "375",
					"unit": "ML",
					"os": "928",
					"ps": "10",
					"cs": "0",
					"quantity":"",
					"image": "53.png"
				},
				{
					"sku": "Seagram's Royal Stag Deluxe Whisky 750ML(0091)",
					"alias": "Seagram's Royal Stag Deluxe Whisky 750ML(0091)",
					"size": "750",
					"unit": "ML",
					"os": "972",
					"ps": "15",
					"cs": "0",
					"quantity":"",
					"image": "54.png"
				},
				{
					"sku": "Seagrams 100 Pipers Blended Scotch Whisky Aged 12 Year 750ML(0179)",
					"alias": "Seagrams 100 Pipers Blended Scotch Whisky Aged 12 Year 750ML(0179)",
					"size": "750",
					"unit": "ML",
					"os": "1016",
					"ps": "20",
					"cs": "0",
					"quantity":"",
					"image": "55.png"
				}

			],
			status:0,
			date:new Date().toISOString().slice(0,10),
			key:0,
			errors:[]
		}
	},

	mounted(){
		this.$refs.quantity.focus();
	},

	methods:{
		editsales(item){
			this.key = item
			this.list = 0
			this.$refs.quantity.focus()
		},
		getImage() {
			var images = require.context('../../assets/items/', false)
			return images('./' +this.items[this.key].image)
		},

		checkQuantity(action) {
			let qty = this.items[this.key].quantity 			
			let stock = +this.items[this.key].os + +this.items[this.key].ps;
			if((stock<this.items[this.key].quantity)||(!qty.match(/^(\s*|\d+)$/))) {
				alert('Please enter valid quantity')
			}
			else {
				this.items[this.key].cs = +this.items[this.key].os + +this.items[this.key].ps - +this.items[this.key].quantity;
				if(action=='Next')
					this.next();
				else if(action=='submit')
					this.showlist()
				else
					this.back();
			}
			
		},

		next() {
			if(this.key!=(this.items.length-1)) {
				this.key++;
				this.$refs.quantity.focus();
			}
			else {
				this.showlist()
			}
		},

		back() {
			if(this.key!=0) {
				this.key--;
				this.$refs.quantity.focus();
			}
		},

		editAlias() {
			this.status=1;
		},

		updateAlias() {
			this.status=0;
		},

		openModal() {
			this.$bvModal.show('confirm')
		},

		closeModal() {
			this.$bvModal.hide('confirm')
		},

		confirmModal() {
			//this.$router.push({ path: '/cl2/sales/register', query: {'items':this.items}})
			this.$bvModal.hide('confirm')
			this.$bvModal.show('success')
		},
		showlist(){
			this.list = 1
		},
		successModal(){
			this.$bvModal.hide('success')
			this.$router.go()
		},
		getTotalCount() {
			let items = this.items.filter(function (el) {
				return el.quantity == '' 
			});
			return items.length;
		},
		getCount() {
			let items = this.items.filter(function (el) {
				return el.quantity != '' 
			});
			return items.length;
		},
		getColor(item) {
			if(item.quantity) {
				return 'background:lightyellow';
			}
		}
	}
}
</script>
