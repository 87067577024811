<template>
	<main>
		<div class="container">
			<div class="excise-register">
				<div class="main row align-items-center">
					<div class="col-sm-6">
						<span class="register">Excise Register</span>
					</div>
					<div class="col-sm-6 text-right">
						<span class="date">{{ date | moment("D MMMM YYYY, dddd") }}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-striped table-bordered">
							<thead>
								<tr class="main-heading">
									<th>KSBCL SKU CODE</th>
									<th>OPENING STOCK</th>
									<th>PURCHASE</th>
									<th>SALES</th>
									<th>CLOSING STOCK</th>
								</tr>
								<tr class="heading">
									<th></th>
									<th>Quantity</th>
									<th>Quantity</th>
									<th>Quantity</th>
									<th>Quantity</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item,key) in items" :key="key">
									<td>{{ item.sku }}</td>
									<td>{{ item.os }}</td>
									<td>{{ item.ps }}</td>
									<td><input class="form-control" type="text" v-model="item.quantity" @keyup="calculateCS(key)"></td>
									<td>{{ item.cs }}</td>
									<td><button class="btn btn-proceed" @click="editsales"><i class="fas fa-edit"></i></button></td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12 text-center">
						<p>Note</p>
					</div>
					<div class="col-sm-12 text-center">
						<p>1. For any change Required, Edit SALES QUANTITY</p>
					</div>
					<div class="col-sm-12 text-center">
						<p>2. Make Sure Closing Stock Matches with Physical Count</p>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-6 text-right">
						<button class="btn btn-back">
							<i class="fas fa-chevron-left"></i>
							BACK
						</button>
					</div>
					<div class="col-sm-6">
						<button class="btn btn-submit" @click="openModal">
							SUBMIT
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</div>

		<b-modal size="lg" id="confirm" centered hide-header="true" hide-footer="true">
			<div class="custom-modal">
				<div class="row">
					<div class="col-sm-12">
						<h4 class="heading">Important</h4>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" checked id="check">
							<label class="form-check-label" for="check">
								Please make sure you have done physical count to match closing balance
							</label>
						</div>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" checked id="check2">
							<label class="form-check-label" for="check2">
								Once confirmed, data cannot be modified later
							</label>
						</div>
					</div>
				</div>
				<div class="row btn-action justify-content-center">
					<div class="col-sm-5">
						<button class="btn btn-back" @click="closeModal">
							<i class="fas fa-chevron-left"></i>
							BACK
						</button>
					</div>
					<div class="col-sm-5">
						<button class="btn btn-proceed" @click="confirmModal">
							SUBMIT
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>

		<b-modal id="success" centered hide-header="true" hide-footer="true">
			<div class="custom-modal">
				<div class="row">
					<div class="col-sm-12 text-center">
						<img class="icon" src="../../assets/success.png">
						<p>Excise Register Successfully Submitted as on</p> 
						<p>{{ date | moment("D MMMM YYYY, dddd") }}</p>
					</div>
				</div>
				<div class="row btn-action justify-content-center">
					<div class="col-sm-5">
						<button class="btn btn-proceed" @click="successModal">
							OK
							<i class="fas fa-chevron-right"></i>
						</button>
					</div>
				</div>
			</div>
		</b-modal>
	</main>
</template>

<script>

export default {
	name: 'CL2-Sales',
	data(){
		return{
			items:[],
			date:new Date().toISOString().slice(0,10),
			key:0,
			errors:[]
		}
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.items = to.query.items
		});
	},

	methods:{
		calculateCS(key){
			let stock = this.items[key].os + this.items[key].ps;
			if(stock<this.items[key].quantity) {
				alert('Please enter valid quantity')
			}
			else {
				this.items[key].cs = +this.items[key].os + +this.items[key].ps - +this.items[key].quantity;
			}
		},

		openModal() {
			this.$bvModal.show('confirm')
		},
		closeModal() {
			this.$bvModal.hide('confirm')
		},
		confirmModal() {
			this.$bvModal.show('success')
		},
		successModal() {
			this.$router.push({ path: '/cl2/sales'})
		},
		editsales(){
			this.$router.push({ path: '/cl2/sales/', query: {'items':this.items}})
		}
	}
}
</script>
